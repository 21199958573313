body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Style all font awesome icons */
.fa {
  padding: 10px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

/* hover effect */
.fa:hover {
  opacity: 0.7;
}

/* Default styling for every social media icon */
.social-media-icon {
  border-radius: 10px;
  color: white;
}

/* Set a specific background color for each brand */
.fa-facebook {
  background: #3B5998;
}

.fa-linkedin {
  background: #007bb5;
}

.fa-instagram {
  font-size: 35px;
  line-height: 30px;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}
